<template>
    <div class="main">
        <iframe 
            id="mainiframe" width="100%" height="600" :src="html_info" @load="changeFrameHeight()" 
            frameborder="0" scrolling="not"
        ></iframe>
    </div>
</template>

<script>
export default {
    data() {
        return {
            html_info: ''
        }            
    },
    created(){
        this.getParams()
    },
    methods:{
        getParams() {

            // 取到路由带过来的参数
            const routerParams = this.$route.query.type;
            let html_page = {
                '1' : () => { return '/agreement_order_01.html' },
                '2' : () => { return '/agreement_order_02.html'},
                '3' : () => { return '/agreement_order_03.html' },
            }
            this.html_info = html_page[routerParams]() 
        },

        changeFrameHeight(){
            let ifm = document.getElementById("mainiframe");
            ifm.height = document.documentElement.clientHeight - 70; 
        }        
    }
}
</script>
